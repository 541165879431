import * as Types from './types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  id
  createdAt
  updatedAt
  code
  description
  permissions
  channels {
    id
    code
    token
  }
}
    `;
export const AdministratorFragmentDoc = gql`
    fragment Administrator on Administrator {
  id
  createdAt
  updatedAt
  firstName
  lastName
  emailAddress
  user {
    id
    identifier
    lastLogin
    roles {
      ...Role
    }
  }
}
    ${RoleFragmentDoc}`;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on CurrentUser {
  id
  identifier
  channels {
    id
    code
    token
    permissions
  }
}
    `;
export const ContractTemplateFragmentDoc = gql`
    fragment ContractTemplate on ContractTemplate {
  id
  createdAt
  updatedAt
  title
  states
  companies
  content
  variables
  settings
  contentFile {
    id
    name
    source
  }
  productTypes
}
    `;
export const PartialContractTemplateFragmentDoc = gql`
    fragment PartialContractTemplate on ContractTemplate {
  id
  createdAt
  updatedAt
  title
  states
  companies
}
    `;
export const ContractSettingsFragmentDoc = gql`
    fragment ContractSettings on ContractSettings {
  id
  plan
  terms {
    length
    divisor
    initialPayment {
      from
      to
      value
    }
    earlyPurchasePercentage
  }
  greaterPaymentOptions
  ldwPrice {
    type
    value {
      from
      to
      value
    }
  }
  fees
}
    `;
export const IntegrationFragmentDoc = gql`
    fragment Integration on Integration {
  id
  createdAt
  updatedAt
  name
  user {
    identifier
  }
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on Payment {
  id
  transactionId
  amount
  method
  state
  metadata
}
    `;
export const RefundFragmentDoc = gql`
    fragment Refund on Refund {
  id
  state
  items
  shipping
  adjustment
  transactionId
  paymentId
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  id
  createdAt
  updatedAt
  orderPlacedAt
  code
  state
  nextStates
  total
  totalWithTax
  currencyCode
  customer {
    id
    firstName
    lastName
  }
  shippingLines {
    shippingMethod {
      name
    }
  }
  customFields {
    organization {
      id
      name
    }
    orderType
    createdBy {
      id
    }
  }
  createdByName
}
    `;
export const DiscountFragmentDoc = gql`
    fragment Discount on Discount {
  adjustmentSource
  amount
  amountWithTax
  description
  type
}
    `;
export const FulfillmentFragmentDoc = gql`
    fragment Fulfillment on Fulfillment {
  id
  state
  nextStates
  createdAt
  updatedAt
  method
  summary {
    orderLine {
      id
    }
    quantity
  }
  trackingCode
}
    `;
export const OrderLineFragmentDoc = gql`
    fragment OrderLine on OrderLine {
  id
  featuredAsset {
    preview
  }
  productVariant {
    id
    name
    sku
    trackInventory
    stockOnHand
    facetValues {
      id
      name
      facet {
        name
        code
      }
    }
  }
  discounts {
    ...Discount
  }
  fulfillments {
    ...Fulfillment
  }
  unitPrice
  unitPriceWithTax
  proratedUnitPrice
  proratedUnitPriceWithTax
  quantity
  items {
    id
    refundId
    cancelled
  }
  linePrice
  lineTax
  linePriceWithTax
  discountedLinePrice
  discountedLinePriceWithTax
}
    ${DiscountFragmentDoc}
${FulfillmentFragmentDoc}`;
export const OrderAddressFragmentDoc = gql`
    fragment OrderAddress on OrderAddress {
  fullName
  company
  streetLine1
  streetLine2
  city
  province
  postalCode
  country
  countryCode
  phoneNumber
}
    `;
export const OrderDetailFragmentDoc = gql`
    fragment OrderDetail on Order {
  id
  createdAt
  updatedAt
  code
  state
  nextStates
  active
  couponCodes
  customer {
    id
    firstName
    lastName
    emailAddress
    phoneNumber
  }
  lines {
    ...OrderLine
  }
  surcharges {
    id
    sku
    description
    price
    priceWithTax
    taxRate
  }
  discounts {
    ...Discount
  }
  promotions {
    id
    couponCode
    name
  }
  subTotal
  subTotalWithTax
  total
  totalWithTax
  currencyCode
  shipping
  shippingWithTax
  shippingLines {
    shippingMethod {
      id
      code
      name
      fulfillmentHandlerCode
      description
    }
  }
  taxSummary {
    description
    taxBase
    taxRate
    taxTotal
  }
  shippingAddress {
    ...OrderAddress
  }
  billingAddress {
    ...OrderAddress
  }
  payments {
    id
    createdAt
    transactionId
    amount
    method
    state
    nextStates
    errorMessage
    metadata
    refunds {
      id
      createdAt
      state
      items
      adjustment
      total
      paymentId
      reason
      transactionId
      method
      metadata
      orderItems {
        id
      }
    }
  }
  fulfillments {
    ...Fulfillment
  }
  modifications {
    id
    createdAt
    isSettled
    priceChange
    note
    payment {
      id
      amount
    }
    orderItems {
      id
    }
    refund {
      id
      paymentId
      total
    }
    surcharges {
      id
    }
  }
  customFields {
    organization {
      id
      name
    }
    orderType
    createdBy {
      id
    }
  }
  rtoContract
  createdByName
}
    ${OrderLineFragmentDoc}
${DiscountFragmentDoc}
${OrderAddressFragmentDoc}
${FulfillmentFragmentDoc}`;
export const OrganizationFragmentDoc = gql`
    fragment Organization on Organization {
  id
  createdAt
  updatedAt
  channel {
    id
    code
    token
  }
  type
  name
  email
  phone
  address {
    street
    street2
    city
    state
    postalCode
  }
  settings
  parentId
}
    `;
export const UpdateOrganizationResultFragmentDoc = gql`
    fragment UpdateOrganizationResult on Organization {
  id
  createdAt
  updatedAt
  type
  name
  email
  phone
  address {
    street
    street2
    city
    state
    postalCode
  }
  settings
  parentId
}
    `;
export const PartialOrganizationFragmentDoc = gql`
    fragment PartialOrganization on Organization {
  id
  createdAt
  updatedAt
  channel {
    id
    code
  }
  type
  name
  email
  phone
  address {
    street
    street2
    city
    state
    postalCode
  }
  parentId
}
    `;
export const ConfigurableOperationFragmentDoc = gql`
    fragment ConfigurableOperation on ConfigurableOperation {
  args {
    name
    value
  }
  code
}
    `;
export const PromotionFragmentDoc = gql`
    fragment Promotion on Promotion {
  id
  createdAt
  updatedAt
  name
  enabled
  couponCode
  perCustomerUsageLimit
  startsAt
  endsAt
  conditions {
    ...ConfigurableOperation
  }
  actions {
    ...ConfigurableOperation
  }
}
    ${ConfigurableOperationFragmentDoc}`;
export const CountryFragmentDoc = gql`
    fragment Country on Country {
  id
  createdAt
  updatedAt
  code
  name
  enabled
  translations {
    id
    languageCode
    name
  }
}
    `;
export const ZoneFragmentDoc = gql`
    fragment Zone on Zone {
  id
  createdAt
  updatedAt
  name
  members {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const TaxCategoryFragmentDoc = gql`
    fragment TaxCategory on TaxCategory {
  id
  createdAt
  updatedAt
  name
  isDefault
}
    `;
export const TaxRateFragmentDoc = gql`
    fragment TaxRate on TaxRate {
  id
  createdAt
  updatedAt
  name
  enabled
  value
  category {
    id
    name
  }
  zone {
    id
    name
  }
  customerGroup {
    id
    name
  }
}
    `;
export const ChannelFragmentDoc = gql`
    fragment Channel on Channel {
  id
  createdAt
  updatedAt
  code
  token
  pricesIncludeTax
  currencyCode
  defaultLanguageCode
  defaultShippingZone {
    id
    name
  }
  defaultTaxZone {
    id
    name
  }
}
    `;
export const PaymentMethodFragmentDoc = gql`
    fragment PaymentMethod on PaymentMethod {
  id
  createdAt
  updatedAt
  name
  code
  description
  enabled
  checker {
    ...ConfigurableOperation
  }
  handler {
    ...ConfigurableOperation
  }
}
    ${ConfigurableOperationFragmentDoc}`;
export const GlobalSettingsFragmentDoc = gql`
    fragment GlobalSettings on GlobalSettings {
  id
  availableLanguages
  trackInventory
  outOfStockThreshold
  serverConfig {
    permissions {
      name
      description
      assignable
    }
    orderProcess {
      name
    }
  }
  customFields {
    signNowClientId
    signNowAuthToken
    signNowAccessToken
    ziptaxKey
    contractProcessingFee
    contractDefaultLDW
    notificationSettings
  }
}
    `;
export const CustomFieldConfigFragmentDoc = gql`
    fragment CustomFieldConfig on CustomField {
  name
  type
  list
  description {
    languageCode
    value
  }
  label {
    languageCode
    value
  }
  readonly
  nullable
  ui
}
    `;
export const StringCustomFieldFragmentDoc = gql`
    fragment StringCustomField on StringCustomFieldConfig {
  ...CustomFieldConfig
  pattern
  options {
    label {
      languageCode
      value
    }
    value
  }
}
    ${CustomFieldConfigFragmentDoc}`;
export const LocaleStringCustomFieldFragmentDoc = gql`
    fragment LocaleStringCustomField on LocaleStringCustomFieldConfig {
  ...CustomFieldConfig
  pattern
}
    ${CustomFieldConfigFragmentDoc}`;
export const TextCustomFieldFragmentDoc = gql`
    fragment TextCustomField on TextCustomFieldConfig {
  ...CustomFieldConfig
}
    ${CustomFieldConfigFragmentDoc}`;
export const BooleanCustomFieldFragmentDoc = gql`
    fragment BooleanCustomField on BooleanCustomFieldConfig {
  ...CustomFieldConfig
}
    ${CustomFieldConfigFragmentDoc}`;
export const IntCustomFieldFragmentDoc = gql`
    fragment IntCustomField on IntCustomFieldConfig {
  ...CustomFieldConfig
  intMin: min
  intMax: max
  intStep: step
}
    ${CustomFieldConfigFragmentDoc}`;
export const FloatCustomFieldFragmentDoc = gql`
    fragment FloatCustomField on FloatCustomFieldConfig {
  ...CustomFieldConfig
  floatMin: min
  floatMax: max
  floatStep: step
}
    ${CustomFieldConfigFragmentDoc}`;
export const DateTimeCustomFieldFragmentDoc = gql`
    fragment DateTimeCustomField on DateTimeCustomFieldConfig {
  ...CustomFieldConfig
  datetimeMin: min
  datetimeMax: max
  datetimeStep: step
}
    ${CustomFieldConfigFragmentDoc}`;
export const RelationCustomFieldFragmentDoc = gql`
    fragment RelationCustomField on RelationCustomFieldConfig {
  ...CustomFieldConfig
  entity
  scalarFields
}
    ${CustomFieldConfigFragmentDoc}`;
export const CustomFieldsFragmentDoc = gql`
    fragment CustomFields on CustomField {
  ... on StringCustomFieldConfig {
    ...StringCustomField
  }
  ... on LocaleStringCustomFieldConfig {
    ...LocaleStringCustomField
  }
  ... on TextCustomFieldConfig {
    ...TextCustomField
  }
  ... on BooleanCustomFieldConfig {
    ...BooleanCustomField
  }
  ... on IntCustomFieldConfig {
    ...IntCustomField
  }
  ... on FloatCustomFieldConfig {
    ...FloatCustomField
  }
  ... on DateTimeCustomFieldConfig {
    ...DateTimeCustomField
  }
  ... on RelationCustomFieldConfig {
    ...RelationCustomField
  }
}
    ${StringCustomFieldFragmentDoc}
${LocaleStringCustomFieldFragmentDoc}
${TextCustomFieldFragmentDoc}
${BooleanCustomFieldFragmentDoc}
${IntCustomFieldFragmentDoc}
${FloatCustomFieldFragmentDoc}
${DateTimeCustomFieldFragmentDoc}
${RelationCustomFieldFragmentDoc}`;
export const JobInfoFragmentDoc = gql`
    fragment JobInfo on Job {
  id
  createdAt
  startedAt
  settledAt
  queueName
  state
  isSettled
  progress
  duration
  data
  result
  error
  retries
  attempts
}
    `;
export const ConfigurableOperationDefFragmentDoc = gql`
    fragment ConfigurableOperationDef on ConfigurableOperationDefinition {
  args {
    name
    type
    required
    defaultValue
    list
    ui
    label
    description
  }
  code
  description
}
    `;
export const ErrorResultFragmentDoc = gql`
    fragment ErrorResult on ErrorResult {
  errorCode
  message
}
    `;
export const GetAdministratorsDocument = gql`
    query GetAdministrators($options: AdministratorListOptions) {
  administrators(options: $options) {
    items {
      ...Administrator
    }
    totalItems
  }
}
    ${AdministratorFragmentDoc}`;
export const GetActiveAdministratorDocument = gql`
    query GetActiveAdministrator {
  activeAdministrator {
    ...Administrator
  }
}
    ${AdministratorFragmentDoc}`;
export const GetAdministratorDocument = gql`
    query GetAdministrator($id: ID!) {
  administrator(id: $id) {
    ...Administrator
  }
}
    ${AdministratorFragmentDoc}`;
export const CreateAdministratorDocument = gql`
    mutation CreateAdministrator($input: CreateAdministratorInput!) {
  createAdministrator(input: $input) {
    ...Administrator
  }
}
    ${AdministratorFragmentDoc}`;
export const UpdateAdministratorDocument = gql`
    mutation UpdateAdministrator($input: UpdateAdministratorInput!) {
  updateAdministrator(input: $input) {
    ...Administrator
  }
}
    ${AdministratorFragmentDoc}`;
export const UpdateActiveAdministratorDocument = gql`
    mutation UpdateActiveAdministrator($input: UpdateActiveAdministratorInput!) {
  updateActiveAdministrator(input: $input) {
    ...Administrator
  }
}
    ${AdministratorFragmentDoc}`;
export const DeleteAdministratorDocument = gql`
    mutation DeleteAdministrator($id: ID!) {
  deleteAdministrator(id: $id) {
    result
    message
  }
}
    `;
export const GetRolesDocument = gql`
    query GetRoles($options: RoleListOptions) {
  roles(options: $options) {
    items {
      ...Role
    }
    totalItems
  }
}
    ${RoleFragmentDoc}`;
export const GetRoleDocument = gql`
    query GetRole($id: ID!) {
  role(id: $id) {
    ...Role
  }
}
    ${RoleFragmentDoc}`;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    ...Role
  }
}
    ${RoleFragmentDoc}`;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    ...Role
  }
}
    ${RoleFragmentDoc}`;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id) {
    result
    message
  }
}
    `;
export const AssignRoleToAdministratorDocument = gql`
    mutation AssignRoleToAdministrator($administratorId: ID!, $roleId: ID!) {
  assignRoleToAdministrator(administratorId: $administratorId, roleId: $roleId) {
    ...Administrator
  }
}
    ${AdministratorFragmentDoc}`;
export const AttemptLoginDocument = gql`
    mutation AttemptLogin($username: String!, $password: String!, $rememberMe: Boolean!) {
  login(username: $username, password: $password, rememberMe: $rememberMe) {
    ...CurrentUser
    ...ErrorResult
  }
}
    ${CurrentUserFragmentDoc}
${ErrorResultFragmentDoc}`;
export const LogOutDocument = gql`
    mutation LogOut {
  logout {
    success
  }
}
    `;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  me {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export const AdminResetPasswordDocument = gql`
    mutation AdminResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password) {
    ...CurrentUser
    ...ErrorResult
  }
}
    ${CurrentUserFragmentDoc}
${ErrorResultFragmentDoc}`;
export const AdminRequestResetPasswordDocument = gql`
    mutation AdminRequestResetPassword($emailAddress: String!) {
  requestPasswordReset(emailAddress: $emailAddress) {
    ... on Success {
      success
    }
    ...ErrorResult
  }
}
    ${ErrorResultFragmentDoc}`;
export const GetContractTemplatesDocument = gql`
    query GetContractTemplates($options: ContractTemplateListOptions) {
  contractTemplates(options: $options) {
    items {
      ...PartialContractTemplate
    }
    totalItems
  }
}
    ${PartialContractTemplateFragmentDoc}`;
export const GetContractTemplateDocument = gql`
    query GetContractTemplate($id: ID!) {
  contractTemplate(id: $id) {
    ...ContractTemplate
  }
}
    ${ContractTemplateFragmentDoc}`;
export const CreateContractTemplateDocument = gql`
    mutation CreateContractTemplate($input: CreateContractTemplateInput!) {
  createContractTemplate(input: $input) {
    ...ContractTemplate
  }
}
    ${ContractTemplateFragmentDoc}`;
export const UpdateContractTemplateDocument = gql`
    mutation UpdateContractTemplate($input: UpdateContractTemplateInput!) {
  updateContractTemplate(input: $input) {
    ...ContractTemplate
  }
}
    ${ContractTemplateFragmentDoc}`;
export const DeleteContractTemplateDocument = gql`
    mutation DeleteContractTemplate($id: ID!) {
  deleteContractTemplate(id: $id) {
    result
    message
  }
}
    `;
export const GetContractSettingsDocument = gql`
    query GetContractSettings($templateId: ID) {
  contractSettings(templateId: $templateId) {
    ...ContractSettings
  }
}
    ${ContractSettingsFragmentDoc}`;
export const UploadContractTemplateFileDocument = gql`
    mutation UploadContractTemplateFile($file: Upload!) {
  uploadContractTemplateFile(file: $file) {
    id
    name
    source
  }
}
    `;
export const GetIntegrationsDocument = gql`
    query GetIntegrations($options: IntegrationListOptions) {
  integrations(options: $options) {
    items {
      ...Integration
    }
    totalItems
  }
}
    ${IntegrationFragmentDoc}`;
export const GetIntegrationDocument = gql`
    query GetIntegration($id: ID!) {
  integration(id: $id) {
    ...Integration
  }
}
    ${IntegrationFragmentDoc}`;
export const CreateIntegrationDocument = gql`
    mutation CreateIntegration($input: CreateIntegrationInput!) {
  createIntegration(input: $input) {
    name
    secretKey
    user {
      identifier
    }
  }
}
    `;
export const UpdateIntegrationDocument = gql`
    mutation UpdateIntegration($input: UpdateIntegrationInput!) {
  updateIntegration(input: $input) {
    ...Integration
  }
}
    ${IntegrationFragmentDoc}`;
export const DeleteIntegrationDocument = gql`
    mutation DeleteIntegration($id: ID!) {
  deleteIntegration(id: $id) {
    result
    message
  }
}
    `;
export const FindOrdersDocument = gql`
    query FindOrders($options: OrderResultsListOptions) {
  findOrders(options: $options) {
    items {
      ...Order
    }
    totalItems
  }
}
    ${OrderFragmentDoc}`;
export const GetOrderListDocument = gql`
    query GetOrderList($options: OrderListOptions) {
  orders(options: $options) {
    items {
      ...Order
    }
    totalItems
  }
}
    ${OrderFragmentDoc}`;
export const GetOrderDocument = gql`
    query GetOrder($id: ID!) {
  order(id: $id) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const SettlePaymentDocument = gql`
    mutation SettlePayment($id: ID!) {
  settlePayment(id: $id) {
    ...Payment
    ...ErrorResult
    ... on SettlePaymentError {
      paymentErrorMessage
    }
    ... on PaymentStateTransitionError {
      transitionError
    }
    ... on OrderStateTransitionError {
      transitionError
    }
  }
}
    ${PaymentFragmentDoc}
${ErrorResultFragmentDoc}`;
export const CancelPaymentDocument = gql`
    mutation CancelPayment($id: ID!) {
  cancelPayment(id: $id) {
    ...Payment
    ...ErrorResult
    ... on CancelPaymentError {
      paymentErrorMessage
    }
    ... on PaymentStateTransitionError {
      transitionError
    }
  }
}
    ${PaymentFragmentDoc}
${ErrorResultFragmentDoc}`;
export const TransitionPaymentToStateDocument = gql`
    mutation TransitionPaymentToState($id: ID!, $state: String!) {
  transitionPaymentToState(id: $id, state: $state) {
    ...Payment
    ...ErrorResult
    ... on PaymentStateTransitionError {
      transitionError
    }
  }
}
    ${PaymentFragmentDoc}
${ErrorResultFragmentDoc}`;
export const CreateFulfillmentDocument = gql`
    mutation CreateFulfillment($input: FulfillOrderInput!) {
  addFulfillmentToOrder(input: $input) {
    ...Fulfillment
    ... on CreateFulfillmentError {
      errorCode
      message
      fulfillmentHandlerError
    }
    ... on FulfillmentStateTransitionError {
      errorCode
      message
      transitionError
    }
    ...ErrorResult
  }
}
    ${FulfillmentFragmentDoc}
${ErrorResultFragmentDoc}`;
export const CancelOrderDocument = gql`
    mutation CancelOrder($input: CancelOrderInput!) {
  cancelOrder(input: $input) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const RefundOrderDocument = gql`
    mutation RefundOrder($input: RefundOrderInput!) {
  refundOrder(input: $input) {
    ...Refund
    ...ErrorResult
  }
}
    ${RefundFragmentDoc}
${ErrorResultFragmentDoc}`;
export const SettleRefundDocument = gql`
    mutation SettleRefund($input: SettleRefundInput!) {
  settleRefund(input: $input) {
    ...Refund
    ...ErrorResult
  }
}
    ${RefundFragmentDoc}
${ErrorResultFragmentDoc}`;
export const GetOrderHistoryDocument = gql`
    query GetOrderHistory($id: ID!, $options: HistoryEntryListOptions) {
  order(id: $id) {
    id
    history(options: $options) {
      totalItems
      items {
        id
        type
        createdAt
        isPublic
        administrator {
          id
          firstName
          lastName
        }
        data
      }
    }
  }
}
    `;
export const AddNoteToOrderDocument = gql`
    mutation AddNoteToOrder($input: AddNoteToOrderInput!) {
  addNoteToOrder(input: $input) {
    id
  }
}
    `;
export const UpdateOrderNoteDocument = gql`
    mutation UpdateOrderNote($input: UpdateOrderNoteInput!) {
  updateOrderNote(input: $input) {
    id
    data
    isPublic
  }
}
    `;
export const DeleteOrderNoteDocument = gql`
    mutation DeleteOrderNote($id: ID!) {
  deleteOrderNote(id: $id) {
    result
    message
  }
}
    `;
export const TransitionOrderToStateDocument = gql`
    mutation TransitionOrderToState($id: ID!, $state: String!) {
  transitionOrderToState(id: $id, state: $state) {
    ...Order
    ...ErrorResult
    ... on OrderStateTransitionError {
      transitionError
    }
  }
}
    ${OrderFragmentDoc}
${ErrorResultFragmentDoc}`;
export const UpdateOrderCustomFieldsDocument = gql`
    mutation UpdateOrderCustomFields($input: UpdateOrderInput!) {
  setOrderCustomFields(input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const TransitionFulfillmentToStateDocument = gql`
    mutation TransitionFulfillmentToState($id: ID!, $state: String!) {
  transitionFulfillmentToState(id: $id, state: $state) {
    ...Fulfillment
    ...ErrorResult
    ... on FulfillmentStateTransitionError {
      transitionError
    }
  }
}
    ${FulfillmentFragmentDoc}
${ErrorResultFragmentDoc}`;
export const GetOrderSummaryDocument = gql`
    query GetOrderSummary($start: DateTime!, $end: DateTime!) {
  orders(
    options: {filter: {orderPlacedAt: {between: {start: $start, end: $end}}}}
  ) {
    totalItems
    items {
      id
      total
      currencyCode
    }
  }
}
    `;
export const ModifyOrderDocument = gql`
    mutation ModifyOrder($input: ModifyOrderInput!) {
  modifyOrder(input: $input) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const AddManualPaymentDocument = gql`
    mutation AddManualPayment($input: ManualPaymentInput!) {
  addManualPaymentToOrder(input: $input) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const CreateDraftOrderDocument = gql`
    mutation CreateDraftOrder {
  createDraftOrder {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const DeleteDraftOrderDocument = gql`
    mutation DeleteDraftOrder($orderId: ID!) {
  deleteDraftOrder(orderId: $orderId) {
    result
    message
  }
}
    `;
export const AddItemToDraftOrderDocument = gql`
    mutation AddItemToDraftOrder($orderId: ID!, $input: AddItemToDraftOrderInput!) {
  addItemToDraftOrder(orderId: $orderId, input: $input) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const AdjustDraftOrderLineDocument = gql`
    mutation AdjustDraftOrderLine($orderId: ID!, $input: AdjustDraftOrderLineInput!) {
  adjustDraftOrderLine(orderId: $orderId, input: $input) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const RemoveDraftOrderLineDocument = gql`
    mutation RemoveDraftOrderLine($orderId: ID!, $orderLineId: ID!) {
  removeDraftOrderLine(orderId: $orderId, orderLineId: $orderLineId) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const SetCustomerForDraftOrderDocument = gql`
    mutation SetCustomerForDraftOrder($orderId: ID!, $customerId: ID, $input: CreateCustomerInput) {
  setCustomerForDraftOrder(
    orderId: $orderId
    customerId: $customerId
    input: $input
  ) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const SetDraftOrderShippingAddressDocument = gql`
    mutation SetDraftOrderShippingAddress($orderId: ID!, $input: CreateAddressInput!) {
  setDraftOrderShippingAddress(orderId: $orderId, input: $input) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const SetDraftOrderBillingAddressDocument = gql`
    mutation SetDraftOrderBillingAddress($orderId: ID!, $input: CreateAddressInput!) {
  setDraftOrderBillingAddress(orderId: $orderId, input: $input) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const ApplyCouponCodeToDraftOrderDocument = gql`
    mutation ApplyCouponCodeToDraftOrder($orderId: ID!, $couponCode: String!) {
  applyCouponCodeToDraftOrder(orderId: $orderId, couponCode: $couponCode) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const RemoveCouponCodeFromDraftOrderDocument = gql`
    mutation RemoveCouponCodeFromDraftOrder($orderId: ID!, $couponCode: String!) {
  removeCouponCodeFromDraftOrder(orderId: $orderId, couponCode: $couponCode) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const DraftOrderEligibleShippingMethodsDocument = gql`
    query DraftOrderEligibleShippingMethods($orderId: ID!) {
  eligibleShippingMethodsForDraftOrder(orderId: $orderId) {
    id
    name
    code
    description
    price
    priceWithTax
    metadata
  }
}
    `;
export const SetDraftOrderShippingMethodDocument = gql`
    mutation SetDraftOrderShippingMethod($orderId: ID!, $shippingMethodId: ID!) {
  setDraftOrderShippingMethod(
    orderId: $orderId
    shippingMethodId: $shippingMethodId
  ) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const GetPaymentListDocument = gql`
    query GetPaymentList($options: PaymentListOptions!) {
  payments(options: $options) {
    items {
      id
      createdAt
      updatedAt
      transactionId
      amount
      state
      method
      metadata
      order {
        id
        customFields {
          orderType
          organization {
            id
            name
          }
        }
        billingAddress {
          fullName
          streetLine1
          streetLine2
          city
          province
          postalCode
        }
      }
      refunds {
        id
        total
      }
    }
    totalItems
  }
}
    `;
export const GetPaymentDocument = gql`
    query GetPayment($paymentId: ID!) {
  payment(id: $paymentId) {
    id
    createdAt
    transactionId
    amount
    method
    state
    nextStates
    errorMessage
    metadata
    refunds {
      id
      createdAt
      state
      items
      adjustment
      total
      paymentId
      reason
      transactionId
      method
      metadata
      orderItems {
        id
      }
    }
    transaction
  }
}
    `;
export const GetOrganizationsDocument = gql`
    query GetOrganizations($options: OrganizationListOptions) {
  organizations(options: $options) {
    items {
      ...PartialOrganization
    }
    totalItems
  }
}
    ${PartialOrganizationFragmentDoc}`;
export const GetActiveOrganizationDocument = gql`
    query GetActiveOrganization {
  activeOrganization {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export const GetOrganizationDocument = gql`
    query GetOrganization($id: ID!) {
  organization(id: $id) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    ...UpdateOrganizationResult
  }
}
    ${UpdateOrganizationResultFragmentDoc}`;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($id: ID!) {
  deleteOrganization(id: $id) {
    result
    message
  }
}
    `;
export const GetPromotionListDocument = gql`
    query GetPromotionList($options: PromotionListOptions) {
  promotions(options: $options) {
    items {
      ...Promotion
    }
    totalItems
  }
}
    ${PromotionFragmentDoc}`;
export const GetAdjustmentOperationsDocument = gql`
    query GetAdjustmentOperations {
  promotionConditions {
    ...ConfigurableOperationDef
  }
  promotionActions {
    ...ConfigurableOperationDef
  }
}
    ${ConfigurableOperationDefFragmentDoc}`;
export const CreatePromotionDocument = gql`
    mutation CreatePromotion($input: CreatePromotionInput!) {
  createPromotion(input: $input) {
    ...Promotion
    ...ErrorResult
  }
}
    ${PromotionFragmentDoc}
${ErrorResultFragmentDoc}`;
export const UpdatePromotionDocument = gql`
    mutation UpdatePromotion($input: UpdatePromotionInput!) {
  updatePromotion(input: $input) {
    ...Promotion
  }
}
    ${PromotionFragmentDoc}`;
export const DeletePromotionDocument = gql`
    mutation DeletePromotion($id: ID!) {
  deletePromotion(id: $id) {
    result
    message
  }
}
    `;
export const SetRtoAppFieldsDocument = gql`
    mutation SetRtoAppFields($input: RtoAppFieldsInput!) {
  setRtoAppFields(input: $input) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const SetRtoFieldsDocument = gql`
    mutation SetRtoFields($input: RtoFieldsInput!) {
  setRtoFields(input: $input) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const SetRtoItemDocument = gql`
    mutation SetRtoItem($input: RtoItemInput!) {
  setRtoItem(input: $input) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const SetRtoCustomerDocument = gql`
    mutation SetRtoCustomer($input: RtoCustomerInput!) {
  setRtoCustomer(input: $input) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const GetRtoPaymentEstimateDocument = gql`
    query GetRtoPaymentEstimate($options: RtoPaymentEstimateOptions!) {
  rtoPaymentEstimate(options: $options) {
    term
    basePayment
    initialPayment
    recurringPayment
    ldwPrice
    ldw
    tax
    taxRate
    requiredPaymentsDown
    greaterPaymentOptions
    processingFee
  }
}
    `;
export const GetRtoOrderFormDataDocument = gql`
    query GetRtoOrderFormData($orderId: ID!) {
  order(id: $orderId) {
    ...OrderDetail
  }
  rtoTermsForOrder(orderId: $orderId) {
    term
    originalInitialPayment
    originalBasePayment
    originalRecurringPayment
    originalTax
    basePayment
    initialPayment
    netInitialPayment
    recurringPayment
    ldwPrice
    ldw
    tax
    taxRate
    requiredPaymentsDown
    greaterPaymentOptions
    enableSecurityDeposit
    processingFee
  }
  rtoConfig
}
    ${OrderDetailFragmentDoc}`;
export const FinalizeContractDocument = gql`
    mutation FinalizeContract($input: FinalizeContractInput!) {
  finalizeContract(input: $input) {
    redirectUrl
  }
}
    `;
export const GetRtoConfigDocument = gql`
    query GetRtoConfig {
  rtoConfig
}
    `;
export const ProcessPaymentDocument = gql`
    mutation ProcessPayment($input: ProcessPaymentInput!) {
  processPayment(input: $input) {
    ...OrderDetail
    ...ErrorResult
  }
}
    ${OrderDetailFragmentDoc}
${ErrorResultFragmentDoc}`;
export const UploadAttachmentDocument = gql`
    mutation UploadAttachment($orderId: String!, $type: String, $file: Upload!) {
  uploadAttachment(orderId: $orderId, type: $type, file: $file) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;
export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($input: DeleteAttachmentInput!) {
  deleteAttachment(input: $input) {
    result
    message
  }
}
    `;
export const UpdateDocumentMetaDataDocument = gql`
    mutation updateDocumentMetaData($input: UpdateDocumentMetaDataInput!) {
  updateDocumentMetaData(input: $input) {
    rtoContract
  }
}
    `;
export const RecordDeliveryDocument = gql`
    mutation RecordDelivery($input: RecordDeliveryInput!) {
  recordDelivery(input: $input) {
    ...Fulfillment
    ... on CreateFulfillmentError {
      errorCode
      message
      fulfillmentHandlerError
    }
    ... on FulfillmentStateTransitionError {
      errorCode
      message
      transitionError
    }
    ...ErrorResult
  }
}
    ${FulfillmentFragmentDoc}
${ErrorResultFragmentDoc}`;
export const GrantDocumentAccessDocument = gql`
    mutation GrantDocumentAccess($orderId: String!, $docType: String!) {
  grantDocumentAccess(orderId: $orderId, docType: $docType) {
    token
  }
}
    `;
export const GetFacetListDocument = gql`
    query GetFacetList($options: FacetListOptions!) {
  facets(options: $options) {
    items {
      id
      name
      code
      values {
        id
        name
        code
      }
    }
    totalItems
  }
}
    `;
export const GetCountryListDocument = gql`
    query GetCountryList($options: CountryListOptions) {
  countries(options: $options) {
    items {
      id
      code
      name
      enabled
    }
    totalItems
  }
}
    `;
export const GetAvailableCountriesDocument = gql`
    query GetAvailableCountries {
  countries(options: {filter: {enabled: {eq: true}}}) {
    items {
      id
      code
      name
      enabled
    }
  }
}
    `;
export const GetCountryDocument = gql`
    query GetCountry($id: ID!) {
  country(id: $id) {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const CreateCountryDocument = gql`
    mutation CreateCountry($input: CreateCountryInput!) {
  createCountry(input: $input) {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const UpdateCountryDocument = gql`
    mutation UpdateCountry($input: UpdateCountryInput!) {
  updateCountry(input: $input) {
    ...Country
  }
}
    ${CountryFragmentDoc}`;
export const DeleteCountryDocument = gql`
    mutation DeleteCountry($id: ID!) {
  deleteCountry(id: $id) {
    result
    message
  }
}
    `;
export const GetZonesDocument = gql`
    query GetZones {
  zones {
    ...Zone
    members {
      createdAt
      updatedAt
      id
      name
      code
      enabled
    }
  }
}
    ${ZoneFragmentDoc}`;
export const GetZoneDocument = gql`
    query GetZone($id: ID!) {
  zone(id: $id) {
    ...Zone
  }
}
    ${ZoneFragmentDoc}`;
export const CreateZoneDocument = gql`
    mutation CreateZone($input: CreateZoneInput!) {
  createZone(input: $input) {
    ...Zone
  }
}
    ${ZoneFragmentDoc}`;
export const UpdateZoneDocument = gql`
    mutation UpdateZone($input: UpdateZoneInput!) {
  updateZone(input: $input) {
    ...Zone
  }
}
    ${ZoneFragmentDoc}`;
export const DeleteZoneDocument = gql`
    mutation DeleteZone($id: ID!) {
  deleteZone(id: $id) {
    message
    result
  }
}
    `;
export const AddMembersToZoneDocument = gql`
    mutation AddMembersToZone($zoneId: ID!, $memberIds: [ID!]!) {
  addMembersToZone(zoneId: $zoneId, memberIds: $memberIds) {
    ...Zone
  }
}
    ${ZoneFragmentDoc}`;
export const RemoveMembersFromZoneDocument = gql`
    mutation RemoveMembersFromZone($zoneId: ID!, $memberIds: [ID!]!) {
  removeMembersFromZone(zoneId: $zoneId, memberIds: $memberIds) {
    ...Zone
  }
}
    ${ZoneFragmentDoc}`;
export const GetTaxCategoriesDocument = gql`
    query GetTaxCategories {
  taxCategories {
    ...TaxCategory
  }
}
    ${TaxCategoryFragmentDoc}`;
export const GetTaxCategoryDocument = gql`
    query GetTaxCategory($id: ID!) {
  taxCategory(id: $id) {
    ...TaxCategory
  }
}
    ${TaxCategoryFragmentDoc}`;
export const CreateTaxCategoryDocument = gql`
    mutation CreateTaxCategory($input: CreateTaxCategoryInput!) {
  createTaxCategory(input: $input) {
    ...TaxCategory
  }
}
    ${TaxCategoryFragmentDoc}`;
export const UpdateTaxCategoryDocument = gql`
    mutation UpdateTaxCategory($input: UpdateTaxCategoryInput!) {
  updateTaxCategory(input: $input) {
    ...TaxCategory
  }
}
    ${TaxCategoryFragmentDoc}`;
export const DeleteTaxCategoryDocument = gql`
    mutation DeleteTaxCategory($id: ID!) {
  deleteTaxCategory(id: $id) {
    result
    message
  }
}
    `;
export const GetTaxRateListDocument = gql`
    query GetTaxRateList($options: TaxRateListOptions) {
  taxRates(options: $options) {
    items {
      ...TaxRate
    }
    totalItems
  }
}
    ${TaxRateFragmentDoc}`;
export const GetTaxRateListSimpleDocument = gql`
    query GetTaxRateListSimple($options: TaxRateListOptions) {
  taxRates(options: $options) {
    items {
      id
      createdAt
      updatedAt
      name
      enabled
      value
      category {
        id
        name
      }
      zone {
        id
        name
      }
    }
    totalItems
  }
}
    `;
export const GetTaxRateDocument = gql`
    query GetTaxRate($id: ID!) {
  taxRate(id: $id) {
    ...TaxRate
  }
}
    ${TaxRateFragmentDoc}`;
export const CreateTaxRateDocument = gql`
    mutation CreateTaxRate($input: CreateTaxRateInput!) {
  createTaxRate(input: $input) {
    ...TaxRate
  }
}
    ${TaxRateFragmentDoc}`;
export const UpdateTaxRateDocument = gql`
    mutation UpdateTaxRate($input: UpdateTaxRateInput!) {
  updateTaxRate(input: $input) {
    ...TaxRate
  }
}
    ${TaxRateFragmentDoc}`;
export const DeleteTaxRateDocument = gql`
    mutation DeleteTaxRate($id: ID!) {
  deleteTaxRate(id: $id) {
    result
    message
  }
}
    `;
export const GetChannelsDocument = gql`
    query GetChannels {
  channels {
    ...Channel
  }
}
    ${ChannelFragmentDoc}`;
export const GetChannelDocument = gql`
    query GetChannel($id: ID!) {
  channel(id: $id) {
    ...Channel
  }
}
    ${ChannelFragmentDoc}`;
export const GetActiveChannelDocument = gql`
    query GetActiveChannel {
  activeChannel {
    ...Channel
  }
}
    ${ChannelFragmentDoc}`;
export const CreateChannelDocument = gql`
    mutation CreateChannel($input: CreateChannelInput!) {
  createChannel(input: $input) {
    ...Channel
    ...ErrorResult
  }
}
    ${ChannelFragmentDoc}
${ErrorResultFragmentDoc}`;
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($input: UpdateChannelInput!) {
  updateChannel(input: $input) {
    ...Channel
    ...ErrorResult
  }
}
    ${ChannelFragmentDoc}
${ErrorResultFragmentDoc}`;
export const DeleteChannelDocument = gql`
    mutation DeleteChannel($id: ID!) {
  deleteChannel(id: $id) {
    result
    message
  }
}
    `;
export const GetPaymentMethodListDocument = gql`
    query GetPaymentMethodList($options: PaymentMethodListOptions!) {
  paymentMethods(options: $options) {
    items {
      ...PaymentMethod
    }
    totalItems
  }
}
    ${PaymentMethodFragmentDoc}`;
export const GetPaymentMethodOperationsDocument = gql`
    query GetPaymentMethodOperations {
  paymentMethodEligibilityCheckers {
    ...ConfigurableOperationDef
  }
  paymentMethodHandlers {
    ...ConfigurableOperationDef
  }
}
    ${ConfigurableOperationDefFragmentDoc}`;
export const GetPaymentMethodDocument = gql`
    query GetPaymentMethod($id: ID!) {
  paymentMethod(id: $id) {
    ...PaymentMethod
  }
}
    ${PaymentMethodFragmentDoc}`;
export const CreatePaymentMethodDocument = gql`
    mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
  createPaymentMethod(input: $input) {
    ...PaymentMethod
  }
}
    ${PaymentMethodFragmentDoc}`;
export const UpdatePaymentMethodDocument = gql`
    mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
  updatePaymentMethod(input: $input) {
    ...PaymentMethod
  }
}
    ${PaymentMethodFragmentDoc}`;
export const DeletePaymentMethodDocument = gql`
    mutation DeletePaymentMethod($id: ID!, $force: Boolean) {
  deletePaymentMethod(id: $id, force: $force) {
    result
    message
  }
}
    `;
export const GetGlobalSettingsDocument = gql`
    query GetGlobalSettings {
  globalSettings {
    ...GlobalSettings
  }
}
    ${GlobalSettingsFragmentDoc}`;
export const UpdateGlobalSettingsDocument = gql`
    mutation UpdateGlobalSettings($input: UpdateGlobalSettingsInput!) {
  updateGlobalSettings(input: $input) {
    ...GlobalSettings
    ...ErrorResult
  }
}
    ${GlobalSettingsFragmentDoc}
${ErrorResultFragmentDoc}`;
export const GetServerConfigDocument = gql`
    query GetServerConfig {
  globalSettings {
    id
    serverConfig {
      orderProcess {
        name
        to
      }
      permittedAssetTypes
      permissions {
        name
        description
        assignable
      }
      customFieldConfig {
        Address {
          ...CustomFields
        }
        Administrator {
          ...CustomFields
        }
        Asset {
          ...CustomFields
        }
        Channel {
          ...CustomFields
        }
        Collection {
          ...CustomFields
        }
        Country {
          ...CustomFields
        }
        Customer {
          ...CustomFields
        }
        CustomerGroup {
          ...CustomFields
        }
        Facet {
          ...CustomFields
        }
        FacetValue {
          ...CustomFields
        }
        Fulfillment {
          ...CustomFields
        }
        GlobalSettings {
          ...CustomFields
        }
        Order {
          ...CustomFields
        }
        OrderLine {
          ...CustomFields
        }
        PaymentMethod {
          ...CustomFields
        }
        Product {
          ...CustomFields
        }
        ProductOption {
          ...CustomFields
        }
        ProductOptionGroup {
          ...CustomFields
        }
        ProductVariant {
          ...CustomFields
        }
        Promotion {
          ...CustomFields
        }
        ShippingMethod {
          ...CustomFields
        }
        TaxCategory {
          ...CustomFields
        }
        TaxRate {
          ...CustomFields
        }
        User {
          ...CustomFields
        }
        Zone {
          ...CustomFields
        }
      }
    }
  }
}
    ${CustomFieldsFragmentDoc}`;
export const GetJobInfoDocument = gql`
    query GetJobInfo($id: ID!) {
  job(jobId: $id) {
    ...JobInfo
  }
}
    ${JobInfoFragmentDoc}`;
export const GetAllJobsDocument = gql`
    query GetAllJobs($options: JobListOptions) {
  jobs(options: $options) {
    items {
      ...JobInfo
    }
    totalItems
  }
}
    ${JobInfoFragmentDoc}`;
export const GetJobsByIdDocument = gql`
    query GetJobsById($ids: [ID!]!) {
  jobsById(jobIds: $ids) {
    ...JobInfo
  }
}
    ${JobInfoFragmentDoc}`;
export const GetJobQueueListDocument = gql`
    query GetJobQueueList {
  jobQueues {
    name
    running
  }
}
    `;
export const CancelJobDocument = gql`
    mutation CancelJob($id: ID!) {
  cancelJob(jobId: $id) {
    ...JobInfo
  }
}
    ${JobInfoFragmentDoc}`;
export const ReindexDocument = gql`
    mutation Reindex {
  reindex {
    ...JobInfo
  }
}
    ${JobInfoFragmentDoc}`;
export const GetPendingSearchIndexUpdatesDocument = gql`
    query GetPendingSearchIndexUpdates {
  pendingSearchIndexUpdates
}
    `;
export const RunPendingSearchIndexUpdatesDocument = gql`
    mutation RunPendingSearchIndexUpdates {
  runPendingSearchIndexUpdates {
    success
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetAdministrators(variables?: Types.GetAdministratorsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetAdministratorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetAdministratorsQuery>(GetAdministratorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAdministrators', 'query');
    },
    GetActiveAdministrator(variables?: Types.GetActiveAdministratorQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetActiveAdministratorQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetActiveAdministratorQuery>(GetActiveAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetActiveAdministrator', 'query');
    },
    GetAdministrator(variables: Types.GetAdministratorQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetAdministratorQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetAdministratorQuery>(GetAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAdministrator', 'query');
    },
    CreateAdministrator(variables: Types.CreateAdministratorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateAdministratorMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateAdministratorMutation>(CreateAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAdministrator', 'mutation');
    },
    UpdateAdministrator(variables: Types.UpdateAdministratorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateAdministratorMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateAdministratorMutation>(UpdateAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAdministrator', 'mutation');
    },
    UpdateActiveAdministrator(variables: Types.UpdateActiveAdministratorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateActiveAdministratorMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateActiveAdministratorMutation>(UpdateActiveAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateActiveAdministrator', 'mutation');
    },
    DeleteAdministrator(variables: Types.DeleteAdministratorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteAdministratorMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteAdministratorMutation>(DeleteAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteAdministrator', 'mutation');
    },
    GetRoles(variables?: Types.GetRolesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetRolesQuery>(GetRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRoles', 'query');
    },
    GetRole(variables: Types.GetRoleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetRoleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetRoleQuery>(GetRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRole', 'query');
    },
    CreateRole(variables: Types.CreateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateRoleMutation>(CreateRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateRole', 'mutation');
    },
    UpdateRole(variables: Types.UpdateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateRoleMutation>(UpdateRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateRole', 'mutation');
    },
    DeleteRole(variables: Types.DeleteRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteRoleMutation>(DeleteRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteRole', 'mutation');
    },
    AssignRoleToAdministrator(variables: Types.AssignRoleToAdministratorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AssignRoleToAdministratorMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AssignRoleToAdministratorMutation>(AssignRoleToAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AssignRoleToAdministrator', 'mutation');
    },
    AttemptLogin(variables: Types.AttemptLoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AttemptLoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AttemptLoginMutation>(AttemptLoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AttemptLogin', 'mutation');
    },
    LogOut(variables?: Types.LogOutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.LogOutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.LogOutMutation>(LogOutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LogOut', 'mutation');
    },
    GetCurrentUser(variables?: Types.GetCurrentUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCurrentUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCurrentUserQuery>(GetCurrentUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCurrentUser', 'query');
    },
    AdminResetPassword(variables: Types.AdminResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AdminResetPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AdminResetPasswordMutation>(AdminResetPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AdminResetPassword', 'mutation');
    },
    AdminRequestResetPassword(variables: Types.AdminRequestResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AdminRequestResetPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AdminRequestResetPasswordMutation>(AdminRequestResetPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AdminRequestResetPassword', 'mutation');
    },
    GetContractTemplates(variables?: Types.GetContractTemplatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetContractTemplatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetContractTemplatesQuery>(GetContractTemplatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContractTemplates', 'query');
    },
    GetContractTemplate(variables: Types.GetContractTemplateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetContractTemplateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetContractTemplateQuery>(GetContractTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContractTemplate', 'query');
    },
    CreateContractTemplate(variables: Types.CreateContractTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateContractTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateContractTemplateMutation>(CreateContractTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateContractTemplate', 'mutation');
    },
    UpdateContractTemplate(variables: Types.UpdateContractTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateContractTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateContractTemplateMutation>(UpdateContractTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateContractTemplate', 'mutation');
    },
    DeleteContractTemplate(variables: Types.DeleteContractTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteContractTemplateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteContractTemplateMutation>(DeleteContractTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteContractTemplate', 'mutation');
    },
    GetContractSettings(variables?: Types.GetContractSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetContractSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetContractSettingsQuery>(GetContractSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContractSettings', 'query');
    },
    UploadContractTemplateFile(variables: Types.UploadContractTemplateFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UploadContractTemplateFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UploadContractTemplateFileMutation>(UploadContractTemplateFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadContractTemplateFile', 'mutation');
    },
    GetIntegrations(variables?: Types.GetIntegrationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetIntegrationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetIntegrationsQuery>(GetIntegrationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetIntegrations', 'query');
    },
    GetIntegration(variables: Types.GetIntegrationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetIntegrationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetIntegrationQuery>(GetIntegrationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetIntegration', 'query');
    },
    CreateIntegration(variables: Types.CreateIntegrationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateIntegrationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateIntegrationMutation>(CreateIntegrationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateIntegration', 'mutation');
    },
    UpdateIntegration(variables: Types.UpdateIntegrationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateIntegrationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateIntegrationMutation>(UpdateIntegrationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateIntegration', 'mutation');
    },
    DeleteIntegration(variables: Types.DeleteIntegrationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteIntegrationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteIntegrationMutation>(DeleteIntegrationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteIntegration', 'mutation');
    },
    FindOrders(variables?: Types.FindOrdersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.FindOrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.FindOrdersQuery>(FindOrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindOrders', 'query');
    },
    GetOrderList(variables?: Types.GetOrderListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetOrderListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetOrderListQuery>(GetOrderListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrderList', 'query');
    },
    GetOrder(variables: Types.GetOrderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetOrderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetOrderQuery>(GetOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrder', 'query');
    },
    SettlePayment(variables: Types.SettlePaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SettlePaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SettlePaymentMutation>(SettlePaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SettlePayment', 'mutation');
    },
    CancelPayment(variables: Types.CancelPaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CancelPaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CancelPaymentMutation>(CancelPaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CancelPayment', 'mutation');
    },
    TransitionPaymentToState(variables: Types.TransitionPaymentToStateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.TransitionPaymentToStateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.TransitionPaymentToStateMutation>(TransitionPaymentToStateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TransitionPaymentToState', 'mutation');
    },
    CreateFulfillment(variables: Types.CreateFulfillmentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateFulfillmentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateFulfillmentMutation>(CreateFulfillmentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateFulfillment', 'mutation');
    },
    CancelOrder(variables: Types.CancelOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CancelOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CancelOrderMutation>(CancelOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CancelOrder', 'mutation');
    },
    RefundOrder(variables: Types.RefundOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.RefundOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RefundOrderMutation>(RefundOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RefundOrder', 'mutation');
    },
    SettleRefund(variables: Types.SettleRefundMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SettleRefundMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SettleRefundMutation>(SettleRefundDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SettleRefund', 'mutation');
    },
    GetOrderHistory(variables: Types.GetOrderHistoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetOrderHistoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetOrderHistoryQuery>(GetOrderHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrderHistory', 'query');
    },
    AddNoteToOrder(variables: Types.AddNoteToOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AddNoteToOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddNoteToOrderMutation>(AddNoteToOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddNoteToOrder', 'mutation');
    },
    UpdateOrderNote(variables: Types.UpdateOrderNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateOrderNoteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateOrderNoteMutation>(UpdateOrderNoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOrderNote', 'mutation');
    },
    DeleteOrderNote(variables: Types.DeleteOrderNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteOrderNoteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteOrderNoteMutation>(DeleteOrderNoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrderNote', 'mutation');
    },
    TransitionOrderToState(variables: Types.TransitionOrderToStateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.TransitionOrderToStateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.TransitionOrderToStateMutation>(TransitionOrderToStateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TransitionOrderToState', 'mutation');
    },
    UpdateOrderCustomFields(variables: Types.UpdateOrderCustomFieldsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateOrderCustomFieldsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateOrderCustomFieldsMutation>(UpdateOrderCustomFieldsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOrderCustomFields', 'mutation');
    },
    TransitionFulfillmentToState(variables: Types.TransitionFulfillmentToStateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.TransitionFulfillmentToStateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.TransitionFulfillmentToStateMutation>(TransitionFulfillmentToStateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TransitionFulfillmentToState', 'mutation');
    },
    GetOrderSummary(variables: Types.GetOrderSummaryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetOrderSummaryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetOrderSummaryQuery>(GetOrderSummaryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrderSummary', 'query');
    },
    ModifyOrder(variables: Types.ModifyOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.ModifyOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ModifyOrderMutation>(ModifyOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ModifyOrder', 'mutation');
    },
    AddManualPayment(variables: Types.AddManualPaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AddManualPaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddManualPaymentMutation>(AddManualPaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddManualPayment', 'mutation');
    },
    CreateDraftOrder(variables?: Types.CreateDraftOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateDraftOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateDraftOrderMutation>(CreateDraftOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDraftOrder', 'mutation');
    },
    DeleteDraftOrder(variables: Types.DeleteDraftOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteDraftOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteDraftOrderMutation>(DeleteDraftOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDraftOrder', 'mutation');
    },
    AddItemToDraftOrder(variables: Types.AddItemToDraftOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AddItemToDraftOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddItemToDraftOrderMutation>(AddItemToDraftOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddItemToDraftOrder', 'mutation');
    },
    AdjustDraftOrderLine(variables: Types.AdjustDraftOrderLineMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AdjustDraftOrderLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AdjustDraftOrderLineMutation>(AdjustDraftOrderLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AdjustDraftOrderLine', 'mutation');
    },
    RemoveDraftOrderLine(variables: Types.RemoveDraftOrderLineMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.RemoveDraftOrderLineMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveDraftOrderLineMutation>(RemoveDraftOrderLineDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveDraftOrderLine', 'mutation');
    },
    SetCustomerForDraftOrder(variables: Types.SetCustomerForDraftOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetCustomerForDraftOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetCustomerForDraftOrderMutation>(SetCustomerForDraftOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetCustomerForDraftOrder', 'mutation');
    },
    SetDraftOrderShippingAddress(variables: Types.SetDraftOrderShippingAddressMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetDraftOrderShippingAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetDraftOrderShippingAddressMutation>(SetDraftOrderShippingAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetDraftOrderShippingAddress', 'mutation');
    },
    SetDraftOrderBillingAddress(variables: Types.SetDraftOrderBillingAddressMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetDraftOrderBillingAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetDraftOrderBillingAddressMutation>(SetDraftOrderBillingAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetDraftOrderBillingAddress', 'mutation');
    },
    ApplyCouponCodeToDraftOrder(variables: Types.ApplyCouponCodeToDraftOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.ApplyCouponCodeToDraftOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ApplyCouponCodeToDraftOrderMutation>(ApplyCouponCodeToDraftOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApplyCouponCodeToDraftOrder', 'mutation');
    },
    RemoveCouponCodeFromDraftOrder(variables: Types.RemoveCouponCodeFromDraftOrderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.RemoveCouponCodeFromDraftOrderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveCouponCodeFromDraftOrderMutation>(RemoveCouponCodeFromDraftOrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCouponCodeFromDraftOrder', 'mutation');
    },
    DraftOrderEligibleShippingMethods(variables: Types.DraftOrderEligibleShippingMethodsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DraftOrderEligibleShippingMethodsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DraftOrderEligibleShippingMethodsQuery>(DraftOrderEligibleShippingMethodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DraftOrderEligibleShippingMethods', 'query');
    },
    SetDraftOrderShippingMethod(variables: Types.SetDraftOrderShippingMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetDraftOrderShippingMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetDraftOrderShippingMethodMutation>(SetDraftOrderShippingMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetDraftOrderShippingMethod', 'mutation');
    },
    GetPaymentList(variables: Types.GetPaymentListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPaymentListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPaymentListQuery>(GetPaymentListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPaymentList', 'query');
    },
    GetPayment(variables: Types.GetPaymentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPaymentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPaymentQuery>(GetPaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPayment', 'query');
    },
    GetOrganizations(variables?: Types.GetOrganizationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetOrganizationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetOrganizationsQuery>(GetOrganizationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrganizations', 'query');
    },
    GetActiveOrganization(variables?: Types.GetActiveOrganizationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetActiveOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetActiveOrganizationQuery>(GetActiveOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetActiveOrganization', 'query');
    },
    GetOrganization(variables: Types.GetOrganizationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetOrganizationQuery>(GetOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOrganization', 'query');
    },
    CreateOrganization(variables: Types.CreateOrganizationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateOrganizationMutation>(CreateOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOrganization', 'mutation');
    },
    UpdateOrganization(variables: Types.UpdateOrganizationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateOrganizationMutation>(UpdateOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOrganization', 'mutation');
    },
    DeleteOrganization(variables: Types.DeleteOrganizationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteOrganizationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteOrganizationMutation>(DeleteOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteOrganization', 'mutation');
    },
    GetPromotionList(variables?: Types.GetPromotionListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPromotionListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPromotionListQuery>(GetPromotionListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPromotionList', 'query');
    },
    GetAdjustmentOperations(variables?: Types.GetAdjustmentOperationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetAdjustmentOperationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetAdjustmentOperationsQuery>(GetAdjustmentOperationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAdjustmentOperations', 'query');
    },
    CreatePromotion(variables: Types.CreatePromotionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreatePromotionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreatePromotionMutation>(CreatePromotionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreatePromotion', 'mutation');
    },
    UpdatePromotion(variables: Types.UpdatePromotionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdatePromotionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdatePromotionMutation>(UpdatePromotionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdatePromotion', 'mutation');
    },
    DeletePromotion(variables: Types.DeletePromotionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeletePromotionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeletePromotionMutation>(DeletePromotionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeletePromotion', 'mutation');
    },
    SetRtoAppFields(variables: Types.SetRtoAppFieldsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetRtoAppFieldsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetRtoAppFieldsMutation>(SetRtoAppFieldsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetRtoAppFields', 'mutation');
    },
    SetRtoFields(variables: Types.SetRtoFieldsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetRtoFieldsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetRtoFieldsMutation>(SetRtoFieldsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetRtoFields', 'mutation');
    },
    SetRtoItem(variables: Types.SetRtoItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetRtoItemMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetRtoItemMutation>(SetRtoItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetRtoItem', 'mutation');
    },
    SetRtoCustomer(variables: Types.SetRtoCustomerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.SetRtoCustomerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.SetRtoCustomerMutation>(SetRtoCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetRtoCustomer', 'mutation');
    },
    GetRtoPaymentEstimate(variables: Types.GetRtoPaymentEstimateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetRtoPaymentEstimateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetRtoPaymentEstimateQuery>(GetRtoPaymentEstimateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRtoPaymentEstimate', 'query');
    },
    GetRtoOrderFormData(variables: Types.GetRtoOrderFormDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetRtoOrderFormDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetRtoOrderFormDataQuery>(GetRtoOrderFormDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRtoOrderFormData', 'query');
    },
    FinalizeContract(variables: Types.FinalizeContractMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.FinalizeContractMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.FinalizeContractMutation>(FinalizeContractDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FinalizeContract', 'mutation');
    },
    GetRtoConfig(variables?: Types.GetRtoConfigQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetRtoConfigQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetRtoConfigQuery>(GetRtoConfigDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRtoConfig', 'query');
    },
    ProcessPayment(variables: Types.ProcessPaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.ProcessPaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ProcessPaymentMutation>(ProcessPaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ProcessPayment', 'mutation');
    },
    UploadAttachment(variables: Types.UploadAttachmentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UploadAttachmentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UploadAttachmentMutation>(UploadAttachmentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadAttachment', 'mutation');
    },
    DeleteAttachment(variables: Types.DeleteAttachmentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteAttachmentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteAttachmentMutation>(DeleteAttachmentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteAttachment', 'mutation');
    },
    updateDocumentMetaData(variables: Types.UpdateDocumentMetaDataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateDocumentMetaDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateDocumentMetaDataMutation>(UpdateDocumentMetaDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDocumentMetaData', 'mutation');
    },
    RecordDelivery(variables: Types.RecordDeliveryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.RecordDeliveryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RecordDeliveryMutation>(RecordDeliveryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RecordDelivery', 'mutation');
    },
    GrantDocumentAccess(variables: Types.GrantDocumentAccessMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GrantDocumentAccessMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GrantDocumentAccessMutation>(GrantDocumentAccessDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GrantDocumentAccess', 'mutation');
    },
    GetFacetList(variables: Types.GetFacetListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetFacetListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetFacetListQuery>(GetFacetListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetFacetList', 'query');
    },
    GetCountryList(variables?: Types.GetCountryListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCountryListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCountryListQuery>(GetCountryListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCountryList', 'query');
    },
    GetAvailableCountries(variables?: Types.GetAvailableCountriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetAvailableCountriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetAvailableCountriesQuery>(GetAvailableCountriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAvailableCountries', 'query');
    },
    GetCountry(variables: Types.GetCountryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCountryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCountryQuery>(GetCountryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCountry', 'query');
    },
    CreateCountry(variables: Types.CreateCountryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateCountryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateCountryMutation>(CreateCountryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCountry', 'mutation');
    },
    UpdateCountry(variables: Types.UpdateCountryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateCountryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateCountryMutation>(UpdateCountryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCountry', 'mutation');
    },
    DeleteCountry(variables: Types.DeleteCountryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteCountryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteCountryMutation>(DeleteCountryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCountry', 'mutation');
    },
    GetZones(variables?: Types.GetZonesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetZonesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetZonesQuery>(GetZonesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetZones', 'query');
    },
    GetZone(variables: Types.GetZoneQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetZoneQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetZoneQuery>(GetZoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetZone', 'query');
    },
    CreateZone(variables: Types.CreateZoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateZoneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateZoneMutation>(CreateZoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateZone', 'mutation');
    },
    UpdateZone(variables: Types.UpdateZoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateZoneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateZoneMutation>(UpdateZoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateZone', 'mutation');
    },
    DeleteZone(variables: Types.DeleteZoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteZoneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteZoneMutation>(DeleteZoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteZone', 'mutation');
    },
    AddMembersToZone(variables: Types.AddMembersToZoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.AddMembersToZoneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.AddMembersToZoneMutation>(AddMembersToZoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddMembersToZone', 'mutation');
    },
    RemoveMembersFromZone(variables: Types.RemoveMembersFromZoneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.RemoveMembersFromZoneMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RemoveMembersFromZoneMutation>(RemoveMembersFromZoneDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveMembersFromZone', 'mutation');
    },
    GetTaxCategories(variables?: Types.GetTaxCategoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetTaxCategoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetTaxCategoriesQuery>(GetTaxCategoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTaxCategories', 'query');
    },
    GetTaxCategory(variables: Types.GetTaxCategoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetTaxCategoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetTaxCategoryQuery>(GetTaxCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTaxCategory', 'query');
    },
    CreateTaxCategory(variables: Types.CreateTaxCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateTaxCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateTaxCategoryMutation>(CreateTaxCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTaxCategory', 'mutation');
    },
    UpdateTaxCategory(variables: Types.UpdateTaxCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateTaxCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateTaxCategoryMutation>(UpdateTaxCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTaxCategory', 'mutation');
    },
    DeleteTaxCategory(variables: Types.DeleteTaxCategoryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteTaxCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteTaxCategoryMutation>(DeleteTaxCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTaxCategory', 'mutation');
    },
    GetTaxRateList(variables?: Types.GetTaxRateListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetTaxRateListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetTaxRateListQuery>(GetTaxRateListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTaxRateList', 'query');
    },
    GetTaxRateListSimple(variables?: Types.GetTaxRateListSimpleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetTaxRateListSimpleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetTaxRateListSimpleQuery>(GetTaxRateListSimpleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTaxRateListSimple', 'query');
    },
    GetTaxRate(variables: Types.GetTaxRateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetTaxRateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetTaxRateQuery>(GetTaxRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTaxRate', 'query');
    },
    CreateTaxRate(variables: Types.CreateTaxRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateTaxRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateTaxRateMutation>(CreateTaxRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTaxRate', 'mutation');
    },
    UpdateTaxRate(variables: Types.UpdateTaxRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateTaxRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateTaxRateMutation>(UpdateTaxRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTaxRate', 'mutation');
    },
    DeleteTaxRate(variables: Types.DeleteTaxRateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteTaxRateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteTaxRateMutation>(DeleteTaxRateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTaxRate', 'mutation');
    },
    GetChannels(variables?: Types.GetChannelsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetChannelsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetChannelsQuery>(GetChannelsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetChannels', 'query');
    },
    GetChannel(variables: Types.GetChannelQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetChannelQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetChannelQuery>(GetChannelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetChannel', 'query');
    },
    GetActiveChannel(variables?: Types.GetActiveChannelQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetActiveChannelQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetActiveChannelQuery>(GetActiveChannelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetActiveChannel', 'query');
    },
    CreateChannel(variables: Types.CreateChannelMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreateChannelMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreateChannelMutation>(CreateChannelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateChannel', 'mutation');
    },
    UpdateChannel(variables: Types.UpdateChannelMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateChannelMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateChannelMutation>(UpdateChannelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateChannel', 'mutation');
    },
    DeleteChannel(variables: Types.DeleteChannelMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeleteChannelMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeleteChannelMutation>(DeleteChannelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteChannel', 'mutation');
    },
    GetPaymentMethodList(variables: Types.GetPaymentMethodListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPaymentMethodListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPaymentMethodListQuery>(GetPaymentMethodListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPaymentMethodList', 'query');
    },
    GetPaymentMethodOperations(variables?: Types.GetPaymentMethodOperationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPaymentMethodOperationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPaymentMethodOperationsQuery>(GetPaymentMethodOperationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPaymentMethodOperations', 'query');
    },
    GetPaymentMethod(variables: Types.GetPaymentMethodQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPaymentMethodQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPaymentMethodQuery>(GetPaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPaymentMethod', 'query');
    },
    CreatePaymentMethod(variables: Types.CreatePaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CreatePaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CreatePaymentMethodMutation>(CreatePaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreatePaymentMethod', 'mutation');
    },
    UpdatePaymentMethod(variables: Types.UpdatePaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdatePaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdatePaymentMethodMutation>(UpdatePaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdatePaymentMethod', 'mutation');
    },
    DeletePaymentMethod(variables: Types.DeletePaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.DeletePaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.DeletePaymentMethodMutation>(DeletePaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeletePaymentMethod', 'mutation');
    },
    GetGlobalSettings(variables?: Types.GetGlobalSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetGlobalSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetGlobalSettingsQuery>(GetGlobalSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetGlobalSettings', 'query');
    },
    UpdateGlobalSettings(variables: Types.UpdateGlobalSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.UpdateGlobalSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.UpdateGlobalSettingsMutation>(UpdateGlobalSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateGlobalSettings', 'mutation');
    },
    GetServerConfig(variables?: Types.GetServerConfigQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetServerConfigQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetServerConfigQuery>(GetServerConfigDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetServerConfig', 'query');
    },
    GetJobInfo(variables: Types.GetJobInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetJobInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetJobInfoQuery>(GetJobInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetJobInfo', 'query');
    },
    GetAllJobs(variables?: Types.GetAllJobsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetAllJobsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetAllJobsQuery>(GetAllJobsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAllJobs', 'query');
    },
    GetJobsById(variables: Types.GetJobsByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetJobsByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetJobsByIdQuery>(GetJobsByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetJobsById', 'query');
    },
    GetJobQueueList(variables?: Types.GetJobQueueListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetJobQueueListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetJobQueueListQuery>(GetJobQueueListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetJobQueueList', 'query');
    },
    CancelJob(variables: Types.CancelJobMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.CancelJobMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.CancelJobMutation>(CancelJobDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CancelJob', 'mutation');
    },
    Reindex(variables?: Types.ReindexMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.ReindexMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.ReindexMutation>(ReindexDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Reindex', 'mutation');
    },
    GetPendingSearchIndexUpdates(variables?: Types.GetPendingSearchIndexUpdatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPendingSearchIndexUpdatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPendingSearchIndexUpdatesQuery>(GetPendingSearchIndexUpdatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPendingSearchIndexUpdates', 'query');
    },
    RunPendingSearchIndexUpdates(variables?: Types.RunPendingSearchIndexUpdatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.RunPendingSearchIndexUpdatesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.RunPendingSearchIndexUpdatesMutation>(RunPendingSearchIndexUpdatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RunPendingSearchIndexUpdates', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;