import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useApiSdk } from 'ui-core';
import type { GetOrderQuery, GetPaymentQuery } from 'api-client/types';
import { formatPrice } from '../utilities';

type OrderDetail = NonNullable<GetOrderQuery['order']>;
type Payment = NonNullable<GetPaymentQuery['payment']>;

function printPaymentReceipt(order: OrderDetail, payment: Payment) {
    const printWindow = window.open('', 'PRINT', 'height=600,width=800');

    if (!printWindow || !payment.amount) {
        return;
    }

    let transactionAmount = payment.amount / 100;
    let paymentType = 'Offline';

    if (payment.method == 'credit-card') {
        paymentType = 'Credit Card';
        if (payment.metadata && payment.metadata.type == 'ach') {
            paymentType = 'ACH';
        }
    }

    const paymentDate = new Date(payment.createdAt);
    const paymentFormattedDate = new Intl.DateTimeFormat(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
    }).format(paymentDate);

    let transactionHtml = '';

    if (payment.transaction) {
        transactionAmount = Number(payment.transaction.amount);

        if (payment.transaction?.card) {
            paymentType = `${payment.transaction['card-type']} ending in ${payment.transaction.card}`;
            transactionHtml += `
                <tr>
                    <td>Result</td>
                    <td>${payment.transaction['result'] ?? ''}</td>
                </tr>
                <tr>
                    <td>Transaction ID</td>
                    <td>${payment.transactionId}</td>
                </tr>
                <tr>
                    <td>Batch</td>
                    <td>${payment.transaction['batch-string-id'] ?? ''}</td>
                </tr>
            `;
        } else if (payment.transaction['account-number']) {
            paymentType = `${payment.transaction['account-type']} ending in ${payment.transaction['account-number']}`;
            transactionHtml += `
                <tr>
                    <td>Transaction ID</td>
                    <td>${payment.transactionId}</td>
                </tr>
            `;
        }

        transactionHtml += `
            <tr>
                <td>Merchant ID</td>
                <td>${payment.transaction['merchant-id'] ?? ''}</td>
            </tr>
        `;
    }

    let cityStateZip = '';

    if (order.billingAddress?.city) {
        cityStateZip += order.billingAddress.city + ', ';
    }
    if (order.billingAddress?.province) {
        cityStateZip += order.billingAddress.province + ' ';
    }
    if (order.billingAddress?.postalCode) {
        cityStateZip += order.billingAddress.postalCode;
    }

    let reference = '';

    if (payment.metadata?.reference) {
        reference = `
            <tr>
                <td>Description</td>
                <td>${payment.metadata.reference}</td>
            </tr>
        `;
    }

    let receiptHtml = `
        <h1>Payment Receipt</h1>
        ${order.customFields?.orderType != 'external'
            ? `<div class="section contract-info">Contract ID: ${order.id}</div>`
            : ''
        }
        <div class="section">
            <strong>Customer:</strong>
            ${order.billingAddress?.fullName ? `<div>${order.billingAddress?.fullName}</div>` : ''}
            ${order.billingAddress?.streetLine1 ? `<div>${order.billingAddress?.streetLine1}</div>` : ''}
            ${order.billingAddress?.streetLine2 ? `<div>${order.billingAddress?.streetLine2}</div>` : ''}
            ${cityStateZip ? `${cityStateZip}` : ''}
        </div>
        <div><strong>Transaction:</strong></div>
        <table class="receipt-table">
            <tr class="total-row">
                <td>Total</td>
                <td>${formatPrice(transactionAmount)}</td>
            </tr>
            <tr>
                <td>Date</td>
                <td>${paymentFormattedDate}</td>
            </tr>
            <tr>
                <td>Method</td>
                <td>${paymentType}</td>
            </tr>
            ${reference}
            ${transactionHtml}
        </table>
    `;

    printWindow.document.write('<html><head><title>Payment Receipt</title>');
    printWindow.document.write(`
        <style>
            html {
                font-family: sans-serif;
            }
            h1 {
                font-size: 22px;
                margin-bottom: 1em;
            }
            .receipt-table {
                width:50%; border-collapse:collapse;
            }
            .receipt-table td {
                padding: 0.25em 0;
            }
            .contract-info {
                font-weight: bold;
            }
            .section {
                margin-bottom: 1em;
            }
        </style>
    `);
    printWindow.document.write('</head><body >');
    printWindow.document.write(receiptHtml);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    printWindow.focus();
    printWindow.print();
    printWindow.close();
}

export function usePaymentReceipt(props: {payment?: Payment, order?: OrderDetail}) {
    const sdk = useApiSdk();
    const queryClient = useQueryClient();
    const queryKey = ['getPayment', props.payment?.id];
    const queryResult = useQuery<GetPaymentQuery['payment']>({
        queryKey,
        queryFn: async () => {
            if (!props.payment) {
                return;
            }
            const result = await sdk.GetPayment({ paymentId: props.payment.id });
            return result.payment;
        },
        staleTime: 5 * 60 * 1000, // no need to fetch on component mount
    });

    return {
        print: async () => {
            await queryClient.ensureQueryData(queryKey);

            if (props.order && queryResult.data) {
                printPaymentReceipt(props.order, queryResult.data);
            }
        },
        queryResult,
    };
}
